.FontMontSerret {
    font-family: 'Montserrat', sans-serif;
    color: #fff;
}


.HomeFontRALEWAY {
    font-family: 'Raleway', sans-serif;
}

.HomeFontRALEWAYLIGHT {
    font-family: 'Raleway', sans-serif;
}

.HomeFontEXCULSIVEPUNERATE {
    font-family: 'Shadows Into Light';
}

.FontFamilyMontSerret {
    font-family: 'Montserrat', sans-serif;
}

.YESEVA {
    font-family: 'Yeseva One', cursive;
}

.FontMontSerret {
    font-family: 'Montserrat', sans-serif;
    color: #fff;
}



/* ///footer //// */



.SocialIcon {
    padding: 15px;
    background-color: transparent;
    color: #F47B22;
}

.SocialIcon:hover {
    color: black;
    background-color: white;
    border-radius: 50%;

}


.FooterWebsiteLinkHOVER {
    color: white;
    text-decoration: none;
}

.FooterWebsiteLinkHOVER:hover {
    color: #FED01A;
}

.emailHover {
    color: white;
    text-decoration: none;
}

.emailHover:hover {
    text-decoration: underline;
    color: white;
}


/* DOMAIN  css */


.HoVeREffect {
    color: white;
    text-decoration: none;
}

.HoVeREffect:hover {
    color: rgb(234, 96, 37);
    text-decoration: underline;
}